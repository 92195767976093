import { useMemo } from 'react';
import styles from './index.module.less';
import Computer from '@/assets/images/computer@3x.png';
import SolutionIcon1 from '@/assets/images/solution-icon1.png';
import SolutionIcon2 from '@/assets/images/solution-icon2.png';
import SolutionIcon3 from '@/assets/images/solution-icon3.png';
import SolutionIcon4 from '@/assets/images/solution-icon4.png';
import SolutionPortal1 from '@/assets/images/solution-portal-1.png';
import SolutionPortal2 from '@/assets/images/solution-portal-2.png';
import SolutionPortal3 from '@/assets/images/solution-portal-3.png';
import SolutionBanner from '@/assets/images/solution-banner.png';
import SolutionPortal4 from '@/assets/images/solution-portal-4.png';
import SolutionSchema1 from '@/assets/images/solution/solution-schema1.png';
import HomepageListItem from '@/components/homepage-list-item';
import {Button, Card, Col, Row, Space} from "antd";
import { useNavigate } from 'react-router';

import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { Parallax, OverPack } from 'rc-scroll-anim';
import Texty from "rc-texty";

const HomepageContent = () => {
  const navigator = useNavigate();

  const list = [
    {
      title: '可视化',
      desc: '主流的流程设计器页面，通过可视化操作， 方便快捷的完成流程的编辑。',
    },
    {
      title: '主流UI',
      desc: '支持创建扩展至BPMNjs，完全适配Flowable和Activity。',
    },
    {
      title: '技术栈',
      desc: '最新的前端技术栈，使用BpmnJs，Vue2，ElementUI，扩展最新稳定版Flowable，方便二次开发。',
    },
    {
      title: '多终端适配',
      desc: '支持 pc、pad、mobile 多终端布局适配， 并可查看展示效果。',
    },
    {
      title: '流程设计灵活',
      desc: '可视化建模效率高，自由设定并行、串行、自由流等流程路由',
    },
    {
      title: '功能强大',
      desc: '支持统一模板管理、流程仿真、流程交接等，流程运维更简单',
    },
    {
      title: '符合主流',
      desc: '基于开源的Flowable改造，融入中国流程国情。',
    },
    {
      title: '敏捷开发',
      desc: '可基于流程引擎和开发平台，轻松集成到业务系统，快速构建业务功能。',
    },
    {
      title: '组件丰富',
      desc: '扩展了几十个事件与任务，能满足市面上99%企业的需求与业务',
    }
  ];
  const ListItems = useMemo(() => {
    return list.map((item, index) => (
      <HomepageListItem key={index} title={item.title} desc={item.desc} />
    ));
  }, [list]);

  const toAboutUs = () => {
    location.href="/about";
  }

  return (
    <div>
      <div  style={{minHeight: '300px' }}>
        <div className={styles['solution-banner']} style={{minHeight: '300px' }}>
          <div className={styles['solution-banner-content']}>
            <h1>
              <Texty type={"left"} mode={"smooth"}>
                LCP低代码开发平台全流程管理解决方案
              </Texty>
            </h1>
            <br/> <br/>
            <h3>
              <Texty type={"left"} mode={"smooth"}>
                平台涵盖从表单和流程设计、执行、发布、优化的各个方面完整的流程生命周期支持
              </Texty>
            </h3>
            {/*<div className={styles['solution-banner-content-ctrl']}>
              <Space size={50}>
                <Button size={"large"} type={"primary"} color={"red"}>预约演示</Button>
                <Button size={"large"}>预约演示</Button>
              </Space>
            </div>*/}
          </div>
        </div>
      </div>

      <TweenOne key="0" animation={{ opacity: 1, top: '0' }}
                style={{ opacity: 0, top: '100px' }}
      >
        <h3 className={styles['second-title']}>
          随需定制的业务应用，及可视化第三方系统集成
        </h3>
      </TweenOne>
      <div className={styles['solution-item']}>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Parallax
                animation={[
                  { x: 0, playScale: [0, 0.3], opacity: 1 },
                ]}
                style={{ transform: 'translateX(-100px)', opacity: 0.5 }}
            >
              <Card
                hoverable
              >
                <img src={SolutionIcon1} alt={''} />
                <h4>移动随需定制平台</h4>
                <div className={styles['detail']}>
                  通过低代码体系平台，全面提升大中型企业流程管理水平，加速流程驱动的数字化转型。
                </div>
              </Card>
            </Parallax>
          </Col>
          <Col span={12}>
            <Parallax
                animation={[
                  { x: 0, playScale: [0, 0.3], opacity: 1 },
                ]}
                style={{ transform: 'translateX(100px)', opacity: 0.5 }}
            >
              <Card
                hoverable
              >
                <img src={SolutionIcon2} alt={''} />
                <h4>流程设计规划</h4>
                <div className={styles['detail']}>
                  建立流程管理数字化平台，实现对全生命周期管理的流程变革及数字化。
                  建设统一流程运行、模拟、集成和流程监控平台，助力企业流程数字化转型。
                </div>
              </Card>
            </Parallax>
          </Col>
          <Col span={12}>
            <Parallax
              animation={[
                { x: 0, playScale: [0, 0.3], opacity: 1 },
              ]}
              style={{ transform: 'translateX(-100px)', opacity: 0.5 }}
            >
              <Card
                hoverable
              >
                <img src={SolutionIcon3} alt={''} />
                <h4>深度平台集成</h4>
                <div className={styles['detail']}>
                  深度集成钉钉和飞书移动APP能力：账号及组织集成、消息集成、待办集成、业务应用集成、业务流程集成
                </div>
              </Card>
            </Parallax>
          </Col>
          <Col span={12}>
            <Parallax
              animation={[
                { x: 0, playScale: [0, 0.3], opacity: 1 },
              ]}
              style={{ transform: 'translateX(100px)', opacity: 0.5 }}
            >
              <Card
                hoverable
              >
                <img src={SolutionIcon4} alt={''} />
                <h4>数据分析与挖掘</h4>
                <div className={styles['detail']}>
                  基于流程事件数据和流程模型的组合分析，洞察、识别瓶颈和偏差，诊断性能，支持流程优化。
                </div>
              </Card>
            </Parallax>
          </Col>
        </Row>
      </div>

      <h3 className={styles['second-title']}>
        门户管理应用价值
      </h3>

      <div className={styles['solution-portal-item']}>
        <Parallax
            animation={[
              { y: 0, playScale: [0.2, 0.8], opacity: 1 },
            ]}
            style={{ transform: 'translateY(50px)', opacity: 0 }}
        >
          <Row gutter={20}>
            <Col span={6}>
              <Card
                  hoverable
                  cover={<div className={styles['solution-portal-img1']}></div>}
              >
                <br/>
                <h4>统一工作入口，无需重复登录</h4>
                <br/>
                <div className={styles['detail']}>
                  适应多系统办公环境，实现高度应用集成，为组织打造统一的数字化工作入口，让全员、全域、全程在一个界面处理工作，并按照组织层级、角色场景构建多类型多形态门户，还原业务与管理场景。              </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                  hoverable
                  cover={<div className={styles['solution-portal-img2']}></div>}
              >
                <br/>
                <h4>信息聚合推送，工作有条不紊</h4>
                <br/>
                <div className={styles['detail']}>
                  将分散于各部门、各系统的信息聚合呈现，打破组织内信息壁垒。整合后信息清晰分类分区，引导员工的工作方式，提升工作效率。基于PC和移动双端适应的工作平台，信息无缝衔接、事务随时处理。
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                  hoverable
                  cover={<div className={styles['solution-portal-img3']}></div>}
              >
                <br/>
                <h4>数据集成呈现，提供决策辅助</h4>
                <br/>
                <div className={styles['detail']}>
                  汇聚来自不同系统的数据，以图形化报表形式直观、集中呈现，便于决策层清晰掌握企业经营状况，帮助制定更加准确的业务策略，通过运营数据持续分析优化管理模式和业务协作流程，提升组织绩效。
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                  hoverable
                  cover={<div className={styles['solution-portal-img4']}></div>}
              >
                <br/>
                <h4>彰显企业文化，赋能组织运营</h4>
                <br/>
                <div className={styles['detail']}>
                  高度定制的门户可彰显企业文化，新闻公告、重大事项、知识文档等均通过平台汇聚呈现，构建有效的上传下达体系和知识共享平台，促进组织内部协作共享，提升组织活力，激活个体、赋能组织。
                </div>
              </Card>
            </Col>
          </Row>
        </Parallax>
      </div>

      <h3 className={styles['second-title']}>
        典型场景
      </h3>

      <div className={styles['solution-portal-item']}>
        <Row gutter={20}>
          <Col span={4}>
            <div className={styles['classics-item']}>
              <h4 className={`${styles['classics-title']} ${styles['classics-icon-1']}`}>表单引擎</h4>
              <div >
                多元控件<br/>
                可视设计<br/>
                表单预览<br/>
                数据导入
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={styles['classics-item']}>
              <h4 className={`${styles['classics-title']} ${styles['classics-icon-2']}`}>流程引擎</h4>
              <div >
                可视建模<br/>
                一人多岗<br/>
                自由流程<br/>
                流程仿真
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={styles['classics-item']}>
              <h4 className={`${styles['classics-title']} ${styles['classics-icon-3']}`}>流程集成</h4>
              <div>
                异构集成<br/>
                去业务化<br/>
                数据集成<br/>
                应用集成
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={styles['classics-item']}>
              <h4 className={`${styles['classics-title']} ${styles['classics-icon-4']}`}>流程执行</h4>
              <div>
                移动审批<br/>
                提醒督办<br/>
                流程授权<br/>
                流程监控
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={styles['classics-item']}>
              <h4 className={`${styles['classics-title']} ${styles['classics-icon-5']}`}>流程赋能</h4>
              <div>
                原子知识<br/>
                制度关联<br/>
                知识嵌入<br/>
                流程归档
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={styles['classics-item']}>
              <h4 className={`${styles['classics-title']} ${styles['classics-icon-6']}`}>流程分析</h4>
              <div>
                业务穿透<br/>
                效率分析<br/>
                无效洞察<br/>
                多维报表
              </div>
            </div>
          </Col>
        </Row>
      </div>


      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          实现模式
        </h3>
        <div>
          <img src={SolutionSchema1} style={{width: '100%'}} />
        </div>
      </div>

      <div className={styles['concat-us']}>
        <h3 className={styles['second-title']}>
          联系我们
        </h3>
        <div>
          马上开启数字化之路
          <br/>
          <Button onClick={()=>{toAboutUs()}}>立即体验</Button>
        </div>
      </div>
    </div>
  );
};
export default HomepageContent;
