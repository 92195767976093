import styles from './index.module.less';
import Mine from '../icons/mine';
import { useEffect, useMemo, useState } from 'react';
import Order from '../icons/order';
import WorkOrder from '../icons/work-order';
import Buy from '../icons/buy';
import { Outlet, useLocation, useNavigate } from 'react-router';
const Personal = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const [checkIndex, setCheckIndex] = useState(0);
  useEffect(() => {
    const [_, path] = location.pathname.split('/personal/');
    if (path) {
      const index = menu.findIndex((item) => item.route === path);
      setCheckIndex(index);
    }
  }, []);
  const menu = [
    {
      id: 0,
      icon: <Mine fill={checkIndex === 0 ? '#14BD85' : '#919398'} />,
      title: '个人资料',
      route: '',
    },
    {
      id: 1,
      icon: <Buy fill={checkIndex === 1 ? '#14BD85' : '#919398'} />,
      title: '已购产品',
      route: 'my-product',
    },
    {
      id: 2,
      icon: <Order fill={checkIndex === 2 ? '#14BD85' : '#919398'} />,
      title: '我的订单',
      route: 'order',
    },
/*    {
      id: 3,
      icon: <WorkOrder fill={checkIndex === 3 ? '#14BD85' : '#919398'} />,
      title: '我的工单',
    },*/
  ];
  const Menus = useMemo(() => {
    return menu.map((item, index) => (
      <li
        key={item.id}
        className={
          checkIndex === index
            ? styles['personal-menu-item_check']
            : styles['person-menu-item']
        }
        onClick={() => {
          if (item.route !== undefined) {
            navigator(item.route);
          }
          setCheckIndex(index);
        }}
      >
        {item.icon}
        <span style={{ marginLeft: 8 }}>{item.title}</span>
      </li>
    ));
  }, [menu]);
  return (
    <div className={styles['personal-wrap']}>
      <div className={styles['personal-content-wrap']}>
        <ul className={styles['personal-menu-wrap']}>{Menus}</ul>
        <div className={styles['personal-router-wrap']}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default Personal;
