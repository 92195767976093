import styles from './index.module.less';
import Product1 from '@/views/products/components/product-1/index';
import Product2 from '@/views/products/components/product-2/index';
import Product3 from '@/views/products/components/product-3/index';
import Product3_1 from '@/views/products/components/product-3_1/index';
import Product4 from '@/views/products/components/product-4/index';
import Product5 from '@/views/products/components/product-5/index';
import {Divider, Badge, Row, Col} from 'antd';

const ProductContent = () => {

  return (
    <div className={styles['product-content-container']}>
      <div className={styles['product-content-container-box']}>
        {/*<h3 style={{marginTop: '0', padding: '0'}} className={styles['second-title']}>
          产品
        </h3>*/}

        <div className={styles['product-inner']}>
          <Row gutter={18} justify="space-between" align="top">
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <div className={styles['product-item']}>
                {/*<Badge.Ribbon text={<a className={styles['upgrade-record-link']} href={"/products/change-record?p=1"} target={"_blank"}>更新日志</a>} color="#09882f">*/}
                <Product1/>
                {/*</Badge.Ribbon>*/}
              </div>

            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4} >
              <div className={styles['product-item']}>
                {/*<Badge.Ribbon text={<a className={styles['upgrade-record-link']} href={"/products/change-record?p=1"} target={"_blank"}>更新日志</a>} color="#008efa">*/}
                <Product2/>
                {/*</Badge.Ribbon>*/}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4} >
              <div className={styles['product-item']}>
                <Badge.Ribbon text={<a className={styles['upgrade-record-link']} href={"/products/change-record?p=1"} target={"_blank"}>更新日志</a>} color="pink">
                  <Product3/>
                </Badge.Ribbon>
              </div>

            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4} >
              <div className={styles['product-item']}>
                <Badge.Ribbon text={<a className={styles['upgrade-record-link']} href={"/products/change-record?p=4"} target={"_blank"}>更新日志</a>} color="pink">
                  <Product3_1/>
                </Badge.Ribbon>
              </div>

            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4} >
              <div className={styles['product-item']}>
                <Badge.Ribbon text={<a className={styles['upgrade-record-link']} href={"/products/change-record?p=2"} target={"_blank"}>更新日志</a>} color="#1354ff">
                  <Product4/>
                </Badge.Ribbon>
              </div>

            </Col>

            <Col xs={24} sm={24} md={24} lg={4} xl={4} >
              <div className={styles['product-item']}>
                {/*<Badge.Ribbon text={<a className={styles['upgrade-record-link']} href={"/products/change-record?p=1"} target={"_blank"}>更新日志</a>} color="pink">*/}
                <Product5/>
                {/*</Badge.Ribbon>*/}
              </div>

            </Col>
          </Row>

        </div>
      </div>
      <div className={styles['product-desc']}>
        <strong>说明：</strong>
        <br/>
        <p>
          1. 开源版BPM平台：基于 flowable和 bpmn.js 封装的流程引擎平台，后台采用Springboot、Mybatis-plus、Ehcache、Shiro等框架技术，前端采用Vue3&Antd和Vbe架构。
          提供最基础版的表单设计器和流程引擎设计器简单入门级功能，适合流程引擎入门者学习教程。
        </p>
        <p>
          2. 开源版流程设计器：目前开源版区分 Vue 2 和 Vue 3 两个版本，依赖 bpmn-js 的版本较低，部分逻辑设计存在缺陷；具有自定义元素以及activiti、flowable、camunda三个流程引擎的基础适配。
          适合作为 “bpmn-js” 入门教程，了解 BPMN 编辑器的基础结构和开发方式。
        </p>
        <p>
          3. 企业版流程设计器：在开源版本的基础上，基于最新的 bpmn-js 与 vue 3，完善了逻辑设计和 TypeScript 类型声明；并且与 Flowable 流程引擎深度融合，结合实际业务场景和使用方式，对属性编辑面板进行了重新设计，优化了用户体验。
          增加了符合业务场景的流程校验与进度预览、引入了富文本编辑器与代码编辑器。 结合后端引擎，可直接嵌入系统中使用。由于每个公司业务数据不一样，此企业版设计器后端接口仅提供静态json数据返回，购买者需自己实现该接口业务，结构可参考json格式定义。
        </p>
        <p>
          4. FlowablePlus插件版：在企业版流程设计器的基础上，后台基于Flowable流程引擎并扩展了几十个组件应用到多个实战项目中，并增加了决策引擎模块相关功能。简洁的界面风格，强大的流程属性配置功能； 帮助开发者从传统繁杂的流程配置中解放出来，可以快速和简单地定义业务流程。
        </p>
        <p>
          5. 企业版：企业版是LCP低代码开发平台，不是简单的表单、流程设计器和plus插件，而且提供整套开发平台解决方案。企业定制化版本请您先加微信群，我方根据贵方的具体业务进行合理报价。
        </p>
        <p>
          6. 不同产品之间功能差异交大，如您不能确定贵公司适合使用某款产品，欢迎您给我们留言或加微信群沟通。
        </p>
        <p>
          7. 产品权益和价格会根据市场不定时变化，以官网发布的内容为准，最终解释权归深圳途浪科技有限公司所有。
        </p>
      </div>
    </div>
  );
};
export default ProductContent;
