import { useMemo } from 'react';
import styles from './index.module.less';
import Computer from '@/assets/images/computer@3x.png';
import banner1 from '@/assets/images/banner/banner-1.jpg';
import banner2 from '@/assets/images/banner/banner-2.jpg';
import banner3 from '@/assets/images/banner/banner-3.png';
import banner4 from '@/assets/images/banner/banner-4.png';
import {Carousel, Flex} from 'antd';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';

const HomeBanner = () => {
  const contentStyle: React.CSSProperties = {
    minHeight: '400px',
    color: '#fff',
    textAlign: 'center',
    background: '#fff',
  };
// autoplay
  return (
    <div className={styles['home-banner']}>
      <Carousel autoplay={true} autoplaySpeed={4000} speed={500}>
        {/*<div >
          <div className={styles['banner1']}>
            <Flex gap="middle" vertical={false} justify={"space-between"}>
              <Flex justify="center" align="center" vertical className={styles['banner1-content']} >
                <div className={styles['homepage-banner-description_title']}>
                  <Texty type={"left"} mode={"smooth"}>
                    低代码流程引擎开发平台
                  </Texty>
                </div>
                <div className={styles['homepage-banner-description']}>
                  后台基于SpringCloud微服务技术架构，前端使用Vue3技术，前后端框架都支持灵活、无感升级。<br/>
                  为了满足多客户端需求，集成了移动端的钉钉和飞书，还配套了门户系统，企业可实现APP或PC端进行发起、审核相关流程。<br/>
                  方便用户多重需求，我们提供了流程引擎Plus插件和SDK包，还拥有成熟的商业产品平台供您选择。
                </div>
              </Flex>
              <img src={banner1} />
            </Flex>
          </div>
        </div>*/}

        <div >
          <div style={contentStyle} className={styles['homepage-wrap']}>
            <div className={styles['homepage-banner-wrap']}>
              <div className={styles['homepage-banner-description_wrap']}>
                <div className={styles['homepage-banner-description_title']}>
                  FlowablePlus流程引擎
                </div>
                <div className={styles['homepage-banner-description']}>
                  基于 Bpmn.js 的在线流程设计器，简洁的界面风格，强大的流程属性配置功能；
                  帮助开发者从传统繁杂的流程配置中解放出来，可以快速和简单地定义业务流程。
                </div>
                <div className={styles['homepage-banner-btn_wrap']}>
                  <a href={'https://plus.bpmport.com'} target={'_blank'} >
                    <div className={styles['homepage-banner-btn_preview']}>
                      预览
                    </div>
                  </a>
                  <a href={'//www.bpmport.com/bpmn/docs'} target={'_blank'} >
                    <div className={styles['homepage-banner-btn_start']}>
                      开始使用
                    </div>
                  </a>
                  <a target={"_blank"} href='https://gitee.com/lwj/flow' style={{marginLeft: '10px'}}>
                    <img src='https://gitee.com/lwj/flow/badge/star.svg?theme=dark' alt='star' style={{height: '30px'}}></img>
                  </a>
                </div>
              </div>
              <img src={Computer} className={styles['homepage-banner-image']} />
            </div>
          </div>
        </div>
        <div >
          <div className={styles['banner2']}>
            <Flex gap="middle" vertical={false} justify={"space-between"}>
              <Flex style={{width: '600px'}} justify="center" align="center" vertical className={styles['banner1-content']} >
                <div className={styles['homepage-banner-description_title']}>
                  <Texty type={"left"} mode={"smooth"}>
                    深入Flowable流程引擎：核心原理与高阶实战
                  </Texty>
                </div>
                <div className={styles['homepage-banner-description']}>
                  本平台在建设过程中，广泛借鉴并复用了贺波老师的《深入Flowable流程引擎：核心原理与高阶实战》一书中的精髓思想和实用代码。该书对于系统学习及深入掌握Activiti/Flowable的用法具有极高的指导价值，在此向大家郑重推荐。
                </div>
                <div className={styles['homepage-banner-btn_wrap']}>
                  <a href={'https://item.jd.com/10120065419158.html'} target={'_blank'} >
                    <div className={styles['homepage-banner-btn_preview']}>
                      立即购买
                    </div>
                  </a>
                </div>
              </Flex>
              <img src={banner2} />
            </Flex>
          </div>
        </div>

        <div >
          <div className={styles['banner3']}>
            <Flex gap="middle" vertical={false} justify={"space-between"}>
              <Flex style={{width: '600px'}} justify="center" align="center" vertical className={styles['banner1-content']} >
                <div className={styles['homepage-banner-description_title']}>
                  <Texty type={"left"} mode={"smooth"}>
                    FormMaking
                  </Texty>
                </div>
                <div className={styles['homepage-banner-description']}>
                  基于 Vue 的可视化低代码表单设计器，赋能企业实现低代码开发模式；帮助开发者从传统枯燥的表单代码中解放出来，更多关注业务，快速提高效率，节省研发成本。
                </div>
                <div className={styles['homepage-banner-btn_wrap']}>
                  <a href={'https://form.making.link/v3/sample/'} target={'_blank'} >
                    <div className={styles['homepage-banner-btn_preview']}>
                      预览
                    </div>
                  </a>
                  <a href={'https://form.making.link/'} target={'_blank'} >
                    <div className={styles['homepage-banner-btn_start']}>
                      开始使用
                    </div>
                  </a>
                </div>

              </Flex>
              <img src={banner3} style ={{width:'600px'}}/>
            </Flex>
          </div>
        </div>

        <div >
          <div className={styles['banner4']}>
            <Flex gap="middle" vertical={false} justify={"space-between"}>
              <Flex style={{width: '600px'}} justify="center" align="center" vertical className={styles['banner1-content']} >
                <div className={styles['homepage-banner-description_title']}>
                  <Texty type={"left"} mode={"smooth"}>
                    页面设计引擎
                  </Texty>
                </div>
                <div className={styles['homepage-banner-description']}>
                  页面设计引擎为用户提供了一套拖拽配置方式实现web管理应用功能开发，可生成代码工程独立运行，可多人协同开发，在线联调为企业降本增效提供产品保障。
                </div>
                <div className={styles['homepage-banner-btn_wrap']}>
                  <a href={'https://www.zhoushancloud.com/'} target={'_blank'} >
                    <div className={styles['homepage-banner-btn_preview']}>
                      注册试用
                    </div>
                  </a>
                </div>

              </Flex>
              <img src={banner4} style ={{width:'600px'}}/>
            </Flex>
          </div>
        </div>

      </Carousel>
    </div>
  );
};
export default HomeBanner;
