import CommonForm from '@/components/common-form';
import styles from './index.module.less';
import { FormConfig, InputType } from '@/components/common-form/form.types';
import { Button, Radio, message, Timeline } from 'antd';
import { useRef, useState } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import { SaveConcatInfo } from '@/api/contact';
import ContactUsQQ from '@/assets/images/qq-qr-code.jpg';
import AboutUsBanner from '@/assets/images/about-us-banner.jpg';
import { ClockCircleOutlined } from '@ant-design/icons';
const ContactUs = () => {
  const formRef = useRef<FormInstance<any>>(null);
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState(1);
  const validatePhone = (_: any, value: string) => {
    // 使用正则表达式进行验证
    const regex = /^1[0-9]{10}$/; // 只允许字母、数字和下划线
    if (!regex.test(value)) {
      return Promise.reject('请填写正确手机号');
    }
    return Promise.resolve();
  };
  const validateMail = (_: any, value: string) => {
    // 使用正则表达式进行验证
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // 只允许字母、数字和下划线
    if (!regex.test(value)) {
      return Promise.reject('请填写正确邮箱');
    }
    return Promise.resolve();
  };
  const formConfig: FormConfig[] = [
    {
      type: InputType.formitem,
      props: {
        label: '您的名称',
        name: 'name',
        rules: [{ required: true, message: '请填写姓名' }],
        style: {
          width: '100%',
        },
      },
      children: {
        type: InputType.input,
        props: {
          placeholder: '请输入您的名称',
          style: {
            width: '100%',
          },
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        label: '手机号',
        name: 'phone',
        rules: [{ validator: validatePhone, validateTrigger: 'onBlur' }],
        style: {
          width: '100%',
        },
      },
      children: {
        type: InputType.input,
        props: {
          placeholder: '请输入您的手机号',
          style: {
            width: '100%',
          },
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        label: '您的邮箱',
        name: 'email',
        rules: [{ validator: validateMail, validateTrigger: 'onBlur' }],
      },
      children: {
        type: InputType.input,
        props: {
          placeholder: '请输入您的邮箱',
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        label: '请选择您咨询的类型',
        name: 'type',
        rules: [{ required: true, message: '请选择咨询类型' }],
      },
      children: {
        type: InputType.radiogroup,
        props: {
          buttonStyle: 'solid',
          value: radioValue,
          onChange: (e: any) => {
            setRadioValue(e.target.value);
          },
        },
        children: [
          { type: Radio.Button, props: { value: 1 }, children: '购买咨询' },
          {
            type: Radio.Button,
            props: { value: 2 },
            children: '意见&建议',
          },
          { type: Radio.Button, props: { value: 3 }, children: '需求开发' },
          { type: Radio.Button, props: { value: 4 }, children: '其他问题' },
        ],
      },
    },
    {
      type: InputType.formitem,
      props: {
        label: '问题描述',
        name: 'remark',
        rules: [{ required: true, message: '请填写内容' }],
      },
      children: {
        type: InputType.textarea,
        props: {
          placeholder: '问题描述',
        },
      },
    },
  ];

  const submit = async () => {
    setLoading(true);
    try {
      const res = await formRef.current?.validateFields();
      await SaveConcatInfo(res);
      message.success('提交成功');
      formRef.current?.resetFields();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // 2017-3 公司成立
  //           2017-5 开始筹备项目
  //           2018-9 项目权限、基础数据完成
  //           2019-5 完成架构、数据库优化
  //           2019-7 完成集成流程中心flowable
  //           2020-1 发布第一个开源版本
  //           2020-5 升级BPM版本
  //           2020-9 发布第一个开源版本
  //           2021-4集成企业钉钉、飞书
  //           2021-12编写相关文档
  //           2022-6开发SDK
  //           2022-11签约第一个商业版本单
  //           2023-4集成法大大电子签章
  const companyTimeLine = [
    {
      children: '2017-5 开始筹备项目',
      color: 'green',
    },
    {
      children: '2018-9 项目权限、基础数据完成',

    },
    {
      //dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
      children: `2019-5 完成架构、数据库优化`,
    },
    {
      //color: 'red',
      children: '2019-7 完成集成流程中心flowable',
    },
    {
      children: '2020-1 发布第一个开源版本',
    },
    {
      //dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
      children: '2020-5 升级BPM版本',
    },
    {
      children: '2020-9 发布FlowablePlus流程引擎插件',
    },
    {
      children: '2021-4集成企业钉钉、飞书',
    },
    {
      children: '2021-12编写相关文档',
    },
    {
      children: '2022-6开发SDK',
    },
    {
      children: '2022-11签约第一个商业版本单',
    },
    {
      children: '2023-4集成法大大电子签章',
    },
    {
      children: '2023-12研发企业版流程设计器',
    },
    {
      children: '2024-5发布官网',
    },
  ];

  return (
    <div>
      <div className={styles['about-us-banner']} style={{minHeight: '300px' }}>

      </div>

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          关于我们
        </h3>
        <div className={styles['company-desc']}>
          <p>
            深圳途浪科技有限公司成立于2017年03月，专注于低代码流程引擎平台的研发。深耕于业务流程管理、审批流程管理、表单引擎软件和决策引擎等几个方面的研发与创新。创始团队曾服务于世界500强与中国500强和多家上市公司担任要职。
          </p>
          <p>
            途浪科技已为多家国内大中型企业和政府提供了从表单设计、流程梳理、流程设计、流程运行、流程集成、流程挖掘的全生命周期流程软件产品和解决方案。服务的客户分布于制造、电商、食品安全、建材、教育等多个行业中。
          </p>
          <p>
            随着国家对数字化的高度重视和布局以及企业对数字化改造浪潮箭在弦上，公司研发的产品不仅具有很高的前瞻性和时代性，也符合国内企业用户的操作习惯与使用场景，成为流程引擎管理和自动化软件行业的领跑者。
          </p>
        </div>
      </div>

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
        发展历程 History
        </h3>
        <p>
          <Timeline
            mode="alternate"
            items={companyTimeLine}
          />
        </p>
      </div>
      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
        企业文化Company introduction
        </h3>
        <div className={styles['company-slogan']}>
          <div>
            <h4>公司使命</h4>
            —— 致力于为全球企业提供流程解决方案，服务企业，成就员工
          </div>
          <div>
            <h4>公司理念</h4>
            —— 和传统的昨天告别，向规范的未来迈进
          </div>
          <div>
            <h4>公司核心价值观</h4>
            —— 工匠精神做事 ，真诚用心做人
          </div>
          <div>
            <h4>公司愿景</h4>
            —— 做流程行业标杆
          </div>
        </div>
      </div>


      <div className={styles['contact-wrap']}>
        <h3 className={styles['second-title']}>
          联系我们
        </h3>
        <div className={styles['contact-content-wrap']}>

          <p className={styles['contact-content-desc']}>
            如果您有任何疑问，建议和需求，都可以给我们发送信息，我们希望收到您的信息，看看有什么可以帮到您。
          </p>
          <div className={styles['code-wrap']}>

            <div className={styles['code-item-wrap']}>
              <div className={styles['code-item-title_wrap']}>官方交流QQ群</div>
              <div className={styles['code-item-content_wrap']}>
                <p className={styles['code-item-content_desc']}>
                  如有任何问题，请联系专属客服
                </p>
                <img className={styles['code-item-image']} src={ContactUsQQ}></img>
                Flowable企业级应用
                <br/>
                群号：633168411
              </div>
            </div>

          </div>
          <div className={styles['separation']}>or</div>
          <div style={{ width: '100%' }}>
            <CommonForm
              formConfig={formConfig}
              layout="vertical"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              ref={formRef}
            />
          </div>
          <Button
            className={styles['contact-submit']}
            onClick={submit}
            loading={loading}
          >
            发送信息
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
