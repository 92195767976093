import { Suspense } from 'react';
import NotFound from '@/views/404';
import Homepage from '@/views/homepage';
import { history } from './history';
const routers: RouteObject[] = [];
const context = require.context('@/routes', false, /\.router.tsx$/); //获取文件下所有router文件
import HomepageContent from '@/views/homepage-content';
import Products from '@/views/products';
import Solutions from '@/views/solutions';
import Cases from '@/views/cases';
import AboutUs from '@/views/about-us';
import ProductsChangeRecord from '@/views/products-change-record';
context.keys().map((filename) => {
  const file = context(filename);
  routers.push(...file.default);
});
import { useRoutes, RouteObject } from 'react-router-dom';
import CustomRouter from '@/components/router';
import { Spin } from 'antd';
import Login from '@/views/login';
import Register from '@/views/register';
import Personal from '@/views/personal';
import PersonalData from '@/views/personal/data';
import Order from '@/views/personal/order';
import ContactUs from '@/views/contact-us';
import Thanks from '@/views/thanks';
import CommercialLicense from '@/views/commercial-license';
import CreateOrder from '@/views/create-order';
import Agreement from '@/views/create-order/agreement';
import Pay from '@/views/create-order/pay';
import PayResult from '@/views/create-order/pay-result';
import OrderResult from '@/views/create-order/order-result';
import TermsService from "@/views/terms-service";
import FindPwd from "@/views/find-pwd";
import ChangePwd from "@/views/find-pwd";
import MyProduct from "@/views/personal/my-product";
import PayErrorResult from "@/views/create-order/pay-result/pay-error";

export const routes: RouteObject[] = routers.concat([
  {
    path: '/',
    element: <Homepage />,
    children: [
      { path: '', element: <HomepageContent /> },
      {
        path: 'products',
        element: <Products />
      },
      {
        path: 'products/change-record',
        element: <ProductsChangeRecord />
      },
      { path: 'solutions', element: <Solutions /> },
      { path: 'cases', element: <Cases /> },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'find-pwd',
        element: <FindPwd />,
      },
      {
        path: 'change-pwd',
        element: <ChangePwd />,
      },
      {
        path: 'contact',
        element: <ContactUs />,
      },
      {
        path: 'about',
        element: <AboutUs />,
      },
      {
        path: 'personal',
        element: <Personal />,
        children: [
          { path: '', element: <PersonalData /> },
          { path: 'order', element: <Order /> },
          { path: 'my-product', element: <MyProduct /> },
        ],
      },
      {
        path: 'thanks',
        element: <Thanks />,
      },
      {
        path: 'license',
        element: <CommercialLicense />,
      },
      {
        path: 'terms-service',
        element: <TermsService />,
      },
      {
        path: 'order',
        element: <CreateOrder />,
        children: [
          { path: '', element: <Agreement /> },
          { path: 'pay', element: <Pay /> },
          { path: 'orderResult/:orderNo', element: <OrderResult /> },
          { path: 'payResult', element: <PayResult /> },
          { path: 'payErrorResult', element: <PayErrorResult /> },
        ],
      },

    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
const App = () => {
  let elements = useRoutes(routes);
  return elements;
};
const AppWrapper = () => {
  return (
    // <BrowserRouter>
    <CustomRouter history={history}>
      <Suspense
        fallback={
          <Spin spinning={true} tip="加载中。。。" delay={500}>
            <div style={{ minWidth: '100vw', minHeight: '100vh' }}></div>
          </Spin>
        }
      >
        <App />
      </Suspense>
    </CustomRouter>
    // </BrowserRouter>
  );
};
export default AppWrapper;
