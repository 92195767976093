import styles from '../product.module.less';
import {Divider, Space} from 'antd';
import {ProductDetailItem} from '@/views/products/components/common';


const ProductItem2 = () => {
  return (
    <div className={styles['product-card']}>
      <div className={`${styles['product-header']} ${styles['product2']}`}>
        <div className={styles['product-header-title']}>
          <div className={styles['product-header-icon']}></div>
          <h3>开源版流程设计器</h3>
        </div>
        <div className={styles['product-view-url']}>
          <div>
            <Space size={10}>
              <a href={"https://moon-studio.github.io/vite-vue-bpmn-process/"}
                 target={"_blank"}>V3预览</a>
              <a href={"https://designer.bpmport.com/eagle/"}
                 target={"_blank"}>钉钉预览</a>
            </Space>
          </div>
          <div style={{marginTop: '10px'}}>
            <Space size={10}>
              <a href={"https://github.com/moon-studio/vite-vue-bpmn-process"}
                 target={"_blank"}>V3源码</a>
              <a href={"https://gitee.com/lwj/eagle-flow"}
                 target={"_blank"}>钉钉源码</a>
            </Space>
          </div>
        </div>
        <div className={styles['product-header-desc']}>
          流程设计器入门学习
        </div>
      </div>
      <div className={styles['product-price']}>
        <div className={styles['free-box']}>
          免费
        </div>
      </div>
      <Divider/>
      <div className={styles['detail']}>
        <ul>
          <li>
            <ProductDetailItem>
              支持Vue2.x/Vue3.x
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              提供源码、允许二开
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              基础的组件功能
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              基础版流程引擎
            </ProductDetailItem>
          </li>
        </ul>

      </div>
    </div>
  );
};
export default ProductItem2;
