let pathEnv: string;
const envConfig = {
  // development: 'http://localhost:8080',
  development: 'http://192.168.31.12:8080',
  // development: 'http://10.6.15.104:8080',
  sit: 'https://fhgosit-api.newhopedairy.cn',
  uat: 'https://fhgouat-api.newhopedairy.cn',
  production: 'https://fhgo-api.newhopedairy.cn',
};
interface EnvConfig {
  development: string;
  sit: string;
  uat: string;
  production: string;
}
type envKey = keyof EnvConfig;
pathEnv = envConfig[process.env.NODE_ENV as envKey];
export default pathEnv;
