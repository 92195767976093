import styles from './index.module.less';
const PayErrorResult = () => {
  return (
    <div style={{textAlign: "center", fontSize: '24px', color: '#ccc', margin: '100px'}}>
      <div className={styles['pay-code-wrap']}>
        <p className={styles['pay-title']}>支付失败</p>
      </div>
    </div>
  );
};
export default PayErrorResult;
