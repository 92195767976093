import { Outlet, useLocation } from 'react-router';
import styles from './index.module.less';
import { RightOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import CreateProvider from './context';
import { ListProps } from '../commercial-license';
import { getLocalStorage } from '@/util/util';
const CreateOrder = () => {
  const location = useLocation();
  const [curPath, setCurPath] = useState('/order');
  const [goodsInfo, setGoodsInfo] = useState<ListProps | null>(null);
  useEffect(() => {
    if (location.state) {
      setGoodsInfo(location.state);
    } else {
      const info = getLocalStorage('goodsInfo');
      console.log(info, '--------info');
      if (info) {
        setGoodsInfo(JSON.parse(info));
      }
    }
    setCurPath(location.pathname);
  }, [location]);

  return (
    <>
      <CreateProvider value={goodsInfo}>
        <div className={styles['create-order-wrap']}>
          <div className={styles['create-order-process_wrap']}>
            <div
              className={styles['create-order-process_item']}
              style={{
                color: curPath === '/order' ? '#14BD85' : '#C1C4CB',
              }}
            >
              签署协议
              <RightOutlined
                style={{
                  color: '#C1C4CB',
                }}
              />
            </div>
            <div
              className={styles['create-order-process_item']}
              style={{
                color: curPath === '/order/pay' ? '#14BD85' : '#C1C4CB',
              }}
            >
              提交订单
              <RightOutlined
                style={{
                  color: '#C1C4CB',
                }}
              />
            </div>
            <div
              className={styles['create-order-process_item']}
              style={{
                color:
                  curPath === '/order/payResult' || curPath.startsWith('/order/orderResult') ? '#14BD85' : '#C1C4CB',
              }}
            >
              支付
            </div>
          </div>
          <div className={styles['create-order-content_wrap']}>
            <Outlet />
          </div>
        </div>
      </CreateProvider>
    </>
  );
};
export default CreateOrder;
