import { useContext, useEffect, useState } from 'react';
import { CreateOrderContext } from '../context';
import styles from './index.module.less';
import { Button, Radio } from 'antd';
import Zhifubao from '@/assets/images/zhifubao.png';
import Wechat from '@/assets/images/wechat.png';
import { CreateOrderApi } from '@/api/order';
import { setLocalStorage } from '@/util/util';
import { useNavigate } from 'react-router';
const Pay = () => {
  const goodsInfo = useContext(CreateOrderContext);
  const navigator = useNavigate();
  console.log(goodsInfo);
  const [loading, setLoading] = useState(false);
  const [payMethod, setPayMethod] = useState(2);
  const [info, setInfo] = useState<any>(null);
  const submitOrder = async () => {
    setLoading(true);
    try {
      const req = {
        productId: info!.id,
        payWay: payMethod,
      };
      const { data } = await CreateOrderApi(req);
      setLocalStorage('orderInfo', { payWay: payMethod, orderNo: data });
      navigator('/order/orderResult/' + data);
      window.scrollTo(0, 0); // 滚动到页面顶部
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log(goodsInfo, '-------goodsInfo');
    if (goodsInfo) {
      setInfo(goodsInfo);
    }
  }, [goodsInfo]);
  return (
    <div className={styles['pay-wrap']}>
      <div className={styles['pay-content-wrap']}>
        <div className={styles['pay-content-title_wrap']}>支付</div>
        <div className={styles['pay-content']}>
          <ul>
            <li className={styles['pay-content-item']}>
              产　　品：
              {goodsInfo?.productName}</li>
            <li className={styles['pay-content-item']}>
              折&nbsp;&nbsp;后&nbsp;&nbsp;价：
              <span style={{fontSize: '20px', color: 'red' }}></span>
              <span style={{marginLeft: '5px', fontSize: '20px', textDecoration: "line-through", color: 'red'}}>¥{goodsInfo?.originalPrice}</span>
              <span style={{marginLeft: '5px', fontSize: '30px'}}>¥{goodsInfo?.amount}</span>
            </li>
            <li className={styles['pay-content-item']} style={{alignItems:"flex-start"}}>
              产品介绍：<div dangerouslySetInnerHTML={{__html: goodsInfo?.introduce.split(',').join('<br/>')}}></div>
            </li>
            <li className={styles['pay-content-item']}>
              支付方式：
              <Radio.Group
                value={payMethod}
                onChange={(e) => {
                  setPayMethod(e.target.value);
                }}
              >
                {/*<Radio value={1}>
                  <img src={Zhifubao} style={{ width: 102, height: 48 }}></img>
                </Radio>*/}
                <Radio value={2}>
                  <img src={Wechat} style={{ height: 38 }}></img>
                </Radio>
              </Radio.Group>
            </li>
          </ul>
          <Button type="primary" onClick={submitOrder} loading={loading}>
            立即提交
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Pay;
