import React, {useEffect, useMemo, useState} from 'react';
import styles from './index.module.less';
import Logo11 from '@/assets/images/logo.png';
import HomepageListItem from '@/components/homepage-list-item';
import {Card, Carousel, Col, Empty, Row} from "antd";
import {getCasesListByQuery} from "@/api/cases";
const CaseIndex = () => {
  const caseList = [
    {
      id: '11',
      name: '砼联科技',
      img: Logo11,
      detail: '各系统的流程实施模式和方法不统一,不能形成规范和标准 同样的业务规则和管理制度需要反复在不同的系统中实现,难以重复使用 没有统一的流程待办入口,不能汇总待办和流程通知 业务局限性比较大'
    },
    {
      id: '22',
      name: '润建股份',
      img: Logo11,
      detail: '各系统的流程实施模式和方法不统一,不能形成规范和标准 同样的业务规则和管理制度需要反复在不同的系统中实现,难以重复使用 没有统一的流程待办入口,不能汇总待办和流程通知 业务局限性比较大'
    },
    {
      id: '33',
      name: '大汉控股集团有限公司',
      img: Logo11,
      detail: '各系统的流程实施模式和方法不统一,不能形成规范和标准 同样的业务规则和管理制度需要反复在不同的系统中实现,难以重复使用 没有统一的流程待办入口,不能汇总待办和流程通知 业务局限性比较大'
    },
    {
      id: '44',
      name: '湖南悦云数字科技有限公司 ',
      img: Logo11,
      detail: '各系统的流程实施模式和方法不统一,不能形成规范和标准 同样的业务规则和管理制度需要反复在不同的系统中实现,难以重复使用 没有统一的流程待办入口,不能汇总待办和流程通知 业务局限性比较大'
    },
    {
      id: '66',
      name: '浙江亚厦装饰股份有限公司',
      img: Logo11,
      detail: '各系统的流程实施模式和方法不统一,不能形成规范和标准 同样的业务规则和管理制度需要反复在不同的系统中实现,难以重复使用 没有统一的流程待办入口,不能汇总待办和流程通知 业务局限性比较大'
    }
  ];
  const [casesList, setCasesList] = useState<[]>([]);
  const [loading, setLoading] = useState(false);


  // 获取客户案例列表
  const fetchData = async () => {
    setLoading(true);
    try{
      const {data} = await getCasesListByQuery();
      if(data){
        setCasesList(data);
      }
      setLoading(false);
    }catch (e){
      setLoading(false);
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  const caseListItem = useMemo(() => {
    return casesList.map((item, index) => {
      return <Col span={24} key={index} className={styles['case-item']}>
        <Card
          hoverable
          cover={<div className={styles['solution-portal-img1']}></div>}
        >
          <br/>
          <a href={item.url} target={"_blank"} title={item.name}>
            <img src={item.imgUrl} alt={item.name} title={item.name}/>
          </a>
          <a href={item.url} target={"_blank"} title={item.name}>
            <h4>{item.name}</h4>
          </a>
          <br/>
          <div className={styles['detail']}>
            {item.introduction}
          </div>
        </Card>
      </Col>
    });
  }, caseList);


  return (
    <div>
      <div className={styles['case-banner']}>
        <Carousel autoplay>
          <div className={styles['case-banner-item']}>
            <h1 className={styles['case-banner-item-content']}>
              {/*流程性标杆帮助企业以最佳工作流程为基准进行的标杆管理*/}
            </h1>
          </div>
          {/*<div className={styles['case-banner-item']}>
            选择我们，从而进入赶超一流组织创造优秀绩效的良性循环过程
          </div>*/}
        </Carousel>
      </div>

      <div className={styles['case-list']}>
        <Row gutter={20} >
          {caseListItem.length > 0 ? caseListItem : <Col span={24} className={styles['case-item-empty']}><Empty /></Col>}
        </Row>
      </div>

    </div>
  );
};
export default CaseIndex;
