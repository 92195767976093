import {Col, FloatButton, Popover, Tooltip, Row} from 'antd';
import styles from './index.module.less';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {
  MessageOutlined,
  PlayCircleOutlined,
  CommentOutlined,
  CustomerServiceOutlined,
  ToTopOutlined,
  PhoneFilled,
  PhoneOutlined,
} from "@ant-design/icons";
import ContactUsWeixinImg from "@/assets/images/contact-us-weixin-img.png";

const RightBtn = () => {
  const navigator = useNavigate();

  const handleToContactus = () => {
    navigator("/about");
    window.scrollTo(0, 0); // 滚动到页面顶部
  }
  const handleToPreview = () => {
    window.open("https://admin.bpmport.com", "_blank")
  }

  const contactUsContent= () =>{
    return <div className={styles["contact-us"]}>
      <Row gutter={[0, 10]}>
        <Col span={4} style={{fontSize: '20px', lineHeight: '36px'}}>
          <PhoneOutlined />
        </Col>
        <Col span={20}>
          <h3>服务热线，微信号: liucxcy1413</h3>
          <div className={styles["phone"]}>手机号：18371993851</div>
          <div className={styles["phone"]}>邮箱：463805737@qq.com</div>
        </Col>
        <Col span={4} style={{fontSize: '20px', lineHeight: '36px'}}>
          <MessageOutlined />
        </Col>
        <Col span={20}>
          <h3>您的专属顾问</h3>
          <div>
            微信扫描二维码，获取您的专属顾问。
          </div>
          <div className={styles["phone"]}>
            <img src={ContactUsWeixinImg} style={{width: '160px'}} alt={"联系我们"} />
          </div>
        </Col>
      </Row>
    </div>
  }

  return (
    <>
      <FloatButton.Group className={styles["float-btn-grp"]} shape={"square"}>
        <FloatButton
          tooltip={<div>在线预览</div>}
          icon={<PlayCircleOutlined />}
          onClick={handleToPreview}/>
        <Popover content={contactUsContent} placement={"left"}>
          <FloatButton
            icon={<PhoneOutlined />}
            shape="square"/>
        </Popover>

        <FloatButton.BackTop tooltip={<div>回到顶部</div>} visibilityHeight={200} />
      </FloatButton.Group>
    </>
  );
};
export default RightBtn;
