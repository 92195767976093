import {useMemo, useContext, useState, useEffect} from 'react';
import styles from './index.module.less';
import ProductLcp from '@/assets/images/product/product-lcp.jpg';
import ProductHome from '@/assets/images/product/product-home.jpg';
import ProductForm from '@/assets/images/product/product-form.jpg';
import ProductFlow from '@/assets/images/product/product-flow.jpg';
import ProductPlatform from '@/assets/images/product/product-platform.jpg';
import ServiceLayout from '@/assets/images/product/service-layout.jpg';
import PortalHome from '@/assets/images/product/portal-home.jpg';
import PortalTodo from '@/assets/images/product/portal-todo.jpg';
import PortalApprove from '@/assets/images/product/portal-approve.jpg';
import ProductPrivilege from '@/assets/images/product/product-privilege.jpg';
import ContactUsWeixinImg from '@/assets/images/contact-us-weixin-img.png';
import ProductThird from '@/assets/images/product/product-third.png';
import HomepageListItem from '@/components/homepage-list-item';
import ProductContent from '@/views/products/components/product-content/index';
import { useNavigate } from 'react-router';
import { LocalStorageContext } from '@/views/provider/storage-provider';

import {Col, Flex, Row, Alert, Button, Space, Table, Modal, Card} from "antd";
import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { Parallax } from 'rc-scroll-anim';
import Texty from "rc-texty";
import {setLocalStorage} from "@/util/util";
import {ListProps} from "@/views/commercial-license";
import {getCurrentDate} from "@/api/product";

const ProductPage = () => {
  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const navigator = useNavigate();
  const userInfo = useContext(LocalStorageContext);
  const [currentDate, setCurrentDate] = useState<String>("");
  const fetchCurrentDate = async () => {
    try {
      const { data } = await getCurrentDate();
      console.log("currentDate", data);
      setCurrentDate(data);
      // hide loading...
    } catch (error) {
      const date = new Date();
      setCurrentDate(date);
      console.log(error);
    }
  };
  // useEffect(() => {
  //   fetchCurrentDate();
  // }, []);

  //
  const doContactUsDialog = () => {
    setOpenContactUsModal(!openContactUsModal);
  }

  const renderSupportIcon = (supported) =>{
    return <p>
      {supported?<CheckOutlined style={{fontSize: '24px', color: 'rgb(25, 190, 107)'}} />: <span></span>}
    </p>
  }

  const toUseFree = () =>{
    window.open('https://gitee.com/lwj/flow', "_blank");
  }

  const renderFreeVersionColumn = (text, record, index) =>{
    if(record.key === 'price'){
      return <p className={styles['price-box']}>
        <strong className={styles['special-price']} >￥0</strong>&nbsp;/&nbsp;永久免费
      </p>
    } else{
      return renderSupportIcon(record.freeVersionSupported);
    }
  }

  const renderBpmnVersionColumn = (text, record, index) =>{
    if(record.key === 'price'){
      return <div className={styles['price-box']}>
        <p className={styles['original-price-text']}>原价：<span className={styles['original-price']}>{record.bpmnVersionOriginalPrice}</span></p>
        <p className={styles['special-price-text']}>
          限时特价：<strong className={styles['special-price']}>{record.bpmnVersionPrice}</strong> / 永久
        </p>
      </div>
    } else if(record.key === 'itSupport') {
      return <p>{record.bpmnVersionServiceDesc}</p>
    } else{
      return renderSupportIcon(record.bpmnVersionSupported);
    }
  }
  const renderFreeBpmnVersionColumn = (text, record, index) =>{
    if(record.key === 'price'){
      return <p className={styles['price-box']}>
        <strong className={styles['special-price']} >￥0</strong>&nbsp;/&nbsp;永久免费
      </p>
    } else if(record.key === 'itSupport') {
      return <p></p>
    } else if(record.key === 'invoice') {
      return <p></p>
    } else{
      return renderSupportIcon(record.bpmnVersionSupported);
    }
  }

  const renderPluginVersionColumn = (text, record, index) =>{
    if(record.key === 'price'){
      return <div className={styles['price-box']}>
        <p className={styles['original-price-text']}>原价：<span className={styles['original-price']}>{record.pluginVersionOriginalPrice}</span></p>
        <p className={styles['special-price-text']}>
          限时特价：<strong className={styles['special-price']}>{record.pluginVersionPrice}</strong> / 永久
        </p>
      </div>
    } else if(record.key === 'itSupport'){
      return <p>{record.pluginVersionServiceDesc}</p>
    } else{
      return renderSupportIcon(record.pluginVersionSupported);
    }
  }
  const renderCustomizationVersionColumn = (text, record, index) =>{
    if(record.key === 'price'){
      return <div className={styles['price-box']}>
        <p className={styles['special-price-text']} >
          <a onClick={doContactUsDialog} className={styles['online-contact-text']} >在线咨询</a>
        </p>
      </div>
    } else if(record.key === 'itSupport'){
      return <div>{record.customizationVersionServiceDesc}</div>
    } else{
      return renderSupportIcon(record.customizationVersionSupported);
    }
  }
  const columns = [
    {
      width: 180,
      title: '产品功能',
      dataIndex: 'name',
      className: 'product-table-column',
      render: (text, record, index) => <p>
        {record.name}
      </p>,
    },
    {
      // title: '免费版',
      title: () => {
        return <div>
          <div className="page-price-type-icon">
            <div className="page-price-type-icon-light"></div>
          </div>
          开源版BPM平台
        </div>
      },
      width: 170,
      className: 'product-table-column',
      dataIndex: 'freeVersion',
      render: renderFreeVersionColumn,
    },

    {
      title: () =>{
        return <div >
          <div className="page-price-type-icon">
            <div className="page-price-type-icon-dark"></div>
            <div className="page-price-type-icon-light"></div>
          </div>
          开源版流程设计器
        </div>
      },
      className: 'product-table-column',
      dataIndex: 'freePluginVersion',
      render: renderFreeBpmnVersionColumn,
    },
    {
      title: () =>{
        return <div >
          <div className="page-price-type-icon">
            <div className="page-price-type-icon-dark"></div>
            <div className="page-price-type-icon-light"></div>
          </div>
          企业版流程设计器
        </div>
      },
      className: 'product-table-column',
      dataIndex: 'pluginVersion',
      render: renderBpmnVersionColumn,
    },
    {
      title: () => {
        return <div>
          <div className="page-price-type-icon">
            <div className="page-price-type-icon-primary"></div>
            <div className="page-price-type-icon-light"></div>
            <div className="page-price-type-icon-light"></div>
          </div>
          FlowablePlus插件版
        </div>
      },
      width: 230,
      className: 'product-table-column',
      dataIndex: 'prodVersion',
      render: renderPluginVersionColumn,
    },
    ,
    {
      title: () => {
        return <div>
          <div className="page-price-type-icon">
            <div className="page-price-type-icon-primary"></div>
            <div className="page-price-type-icon-light"></div>
            <div className="page-price-type-icon-light"></div>
            <div className="page-price-type-icon-light"></div>
          </div>
          企业版
        </div>
      },
      width: 165,
      className: 'product-table-column',
      dataIndex: 'customizationVersion',
      render: renderCustomizationVersionColumn,
    },
  ];

  const data = [
    {
      key: 'price',
      name: '产品价格',
      freeVersionOriginalPrice: '￥0',
      freeVersionPrice: '￥0',
      bpmnVersionOriginalPrice: '￥2999',
      bpmnVersionPrice: '￥499',
      pluginVersionOriginalPrice: '￥39999',
      pluginVersionPrice: '￥19999',
      customizationVersionPrice: '在线咨询',
      supported: true,
      serviceDesc: '',
      serviceDescDetail: '低代码开发平台为业务高效流转提供坚实高效的技术支持。可视化的表单和流程设计界面，易上手、够强大，无需代码即可轻松创建、发布、维护业务流程应用。',
    },
    {
      key: 'vue',
      name: '支持Vue2.x/Vue3.x',
      freeVersionSupported: true,
      bpmnVersionSupported: true,
      pluginVersionSupported: true,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '',
    },
    {
      key: 'code',
      name: '是否提供源码、允许二开',
      freeVersionSupported: true,
      bpmnVersionSupported: true,
      pluginVersionSupported: true,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '',
    },
    {
      key: 'assembly',
      name: '常用的组件扩展功能',
      freeVersionSupported: false,
      bpmnVersionSupported: true,
      pluginVersionSupported: true,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '',
    },
    {
      key: 'invoice',
      name: '增值税发票',
      freeVersionSupported: false,
      bpmnVersionSupported: true,
      freeBpmnVersionSupported: false,
      pluginVersionSupported: true,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '',
    },
    {
      key: 'form',
      name: '表单引擎设计器',
      freeVersionSupported: true,
      bpmnVersionSupported: false,
      pluginVersionSupported: false,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '基于 Bpmn.js 的在线流程设计器，简洁的界面风格，强大的流程属性配置功能； 帮助开发者从传统繁杂的流程配置中解放出来，可以快速和简单地定义业务流程。',
    },
    {
      key: 'bpmn',
      name: 'BPMN设计器',
      freeVersionSupported: true,
      bpmnVersionSupported: true,
      pluginVersionSupported: true,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '基于 Bpmn.js 的在线流程设计器，简洁的界面风格，强大的流程属性配置功能； 帮助开发者从传统繁杂的流程配置中解放出来，可以快速和简单地定义业务流程。',
    },
    {
      key: 'flowablePlus',
      name: 'FlowablePlus插件',
      freeVersionSupported: false,
      bpmnVersionSupported: false,
      pluginVersionSupported: true,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '',
    },
    {
      key: 'dmn',
      name: 'DMN决策引擎',
      freeVersionSupported: false,
      bpmnVersionSupported: false,
      pluginVersionSupported: true,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '',
    },
    {
      key: 'portal',
      name: '集成门户',
      freeVersionSupported: false,
      bpmnVersionSupported: false,
      pluginVersionSupported: false,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '统一集成门户,将多个业务系统提供统一的访问入口,为用户提供便捷的操作和一站式的服务，合并N个业务系统的流程实现业务数据流整合,' +
        '办事提醒集中展示,为企业提高办事效率,实现降本增效。',
    },
    {
      key: 'sdk',
      name: '流程引擎SDK',
      freeVersionSupported: false,
      bpmnVersionSupported: false,
      pluginVersionSupported: false,
      customizationVersionSupported: true,
      serviceDesc: '',
      serviceDescDetail: '',
    },

    {
      key: 'itSupport',
      name: '技术支持',
      freeVersionSupported: false,
      freeBpmnVersionSupported: false,
      bpmnVersionSupported: true,
      pluginVersionSupported: true,
      bpmnVersionServiceDesc: '1个月（工作日）',
      pluginVersionServiceDesc: '1个月（工作日）',
      customizationVersionServiceDesc: '据合同而定',
      serviceDesc: '',
      serviceDescDetail: '提供免费的售后技术支持与指导，包含技术框架讲解、技术问题咨询、系统bug处理、产品培训等',
    }
  ];

  const goodsList = [
    {"id":1,"productName":"Flowable流程设计器版","productNo":"PT0001","amount":499,"originalPrice":2999,"discount":null,"introduce":"支持Vue2.x/Vue3.x,是否提供源码、允许二开,常用的组件扩展功能,增值税发票,BPMN设计器,技术支持 1个月（工作日）"},
    {"id":2,"productName":"FlowablePlus插件版","productNo":"GJ0001","amount":19999,"originalPrice":39999,"discount":"","introduce":"常支持Vue2.x/Vue3.x,是否提供源码、允许二开,常用的组件扩展功能,增值税发票,BPMN设计器,FlowablePlus插件,技术支持 1个月（工作日）"}
  ];

  const toBuy = (productNo) => {
    if (!userInfo) {
      navigator('/login');
    } else {
      let goodsInfo = goodsList.find(item => item.id == productNo);

      setLocalStorage('goodsInfo', goodsInfo);
      navigator('/order', { state: goodsInfo });
      window.scrollTo(0, 0); // 滚动到页面顶部
    }
  };

  return (
    <div>
      {/*<div className={styles['product-banner']} style={{minHeight: '300px' }}>*/}
      {/*  <div className={styles['product-banner-content']}>*/}
      {/*    <h1>*/}
      {/*      <Texty type={"left"} mode={"smooth"}>*/}
      {/*        LCP低代码开发平台*/}
      {/*      </Texty>*/}
      {/*    </h1>*/}
      {/*    <br/><br/>*/}
      {/*    <h3>*/}
      {/*      <Texty type={"left"} mode={"smooth"}>*/}
      {/*        新一代业务流程平台，和传统的昨天告别，向规范的未来迈进*/}
      {/*      </Texty>*/}
      {/*    </h3>*/}
      {/*    /!*<div className={styles['product-banner-content-ctrl']}>*/}
      {/*      <Space size={50}>*/}
      {/*        <Button size={"large"} type={"primary"} color={"red"}>预约演示</Button>*/}
      {/*        <Button size={"large"}>预约演示</Button>*/}
      {/*      </Space>*/}
      {/*    </div>*!/*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className={styles['product-banner-1']} style={{minHeight: '300px' }}>
        <div className={styles['product-banner-content']}>
          <h1>
            <Texty type={"left"} mode={"smooth"}>
              不忘初心·一路前行
            </Texty>
          </h1>
          <br/>
          <h3>
            <Texty type={"left"} mode={"smooth"}>
              购买平台任意产品即赠送贺波老师的流程引擎书籍一本!
            </Texty>
          </h3>
          <br/>
          <h3>
            <Texty type={"left"} mode={"smooth"}>
              活动时间:2024-11-11~2024-12-12
            </Texty>
          </h3>
        </div>
      </div>
      {/*产品*/}
      <ProductContent />

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          流程中心介绍
        </h3>
        <Row gutter={[40, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Parallax
              animation={[
                { x: 0, opacity: 1, playScale: [0.1, 0.3] }
              ]}
              style={{ transform: 'translateX(-100px)', opacity: 0 }}
            >
              <Flex justify="center" align="center" vertical className={styles['container-msg']}>
                <p>
                  流程中心提供统一的流程设计、流程建模、流程监控，应用系统需要实现流程管理时调用统一的流程中心服务。
                </p>
                <p>
                  流程中心提供标准接口，各应用系统通过调用相关业务流程接口，进行相应业务流程的执行、反馈，流程中心将流程执行过程中产生的业务数据推送至各应用系统。
                </p>
              </Flex>
            </Parallax>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={styles['container-img']}>
              <img src={ProductLcp} alt={"产品主页"}/>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          流程中心功能图
        </h3>
        <Row gutter={[40, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Parallax
                animation={[
                  { x: 0, opacity: 1, playScale: [0, 0.5] }
                ]}
                style={{ transform: 'translateX(-100px)', opacity: 0 }}
            >
              <div className={styles['container-img']}>
                <img src={ProductHome} alt={"产品主页"}/>
              </div>
            </Parallax>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Parallax
                animation={[
                  { x: 0, opacity: 1, playScale: [0, 0.5] }
                ]}
                style={{ transform: 'translateX(100px)', opacity: 0 }}
            >
              <Flex justify="center" align="center" vertical className={styles['container-msg']}>
                <p>
                  平台整合了组织管理、基础数据、流程配置、流程基础配置、运维管理、报表管理、权限管理、系统管理、业务流程管理、数智化门户管理等系统通用基础功能，实现了Docker容器化部署，K8s弹性伸缩，云效流水线快速运维，微服务监控，流量控制，链路跟踪等功能。
                </p>
                <p>
                 为了满足多客户端需求，开发平台集成了移动端的企业钉钉和企业飞书，根据不同公司场景，后台可以灵活配置、动态切换。平台还配套了门户系统，企业根据自身需求，可以随时APP端或门户PC端进行发起、审核相关流程
                </p>
                </Flex>
            </Parallax>
          </Col>
        </Row>
      </div>

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          低代码开发平台
        </h3>

        <div className={styles['home-solutions']}>
          <Row gutter={[40, 16]} style={{marginBottom: '20px'}}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Flex justify="center" align="center" vertical className={styles['container-msg']}>
                <div>
                  <h3>LCP低代码开发平台</h3>
                  低代码开发平台后台是基于SpringCloud微服务技术架构的低代码开发平台，前端使用最新框架Vue3技术，前后端框架都支持灵活、无感升级。<br/>
                  低代码开发平台不仅提供了流程引擎Plus插件和SDK包，用户可以选择直接用我们的成熟产品系统，也可以选择只对接我们的流程引擎框架jar包。为了使客户能够快捷方便的对接使用我们的平台，我们提供了丰富、专业的相关操作使用文档。<br/>
                </div>
              </Flex>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className={styles['container-img']}>
                <img src={ProductPlatform} alt={"产品主页"}/>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          流程/表单管理
        </h3>
        <Row gutter={[40, 16]} style={{marginBottom: '20px'}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex justify="center" align="center" vertical className={styles['container-msg']}>
              <div>
                <h3>流程引擎</h3>
                "高效的流程和审批系统"，让您的各种业务都能"流动" 起来，更简单有效、更自由灵活的办公。<br/>
                新增功能支持每个步骤对应一个表单，同时引入"变量"概念并且支持各种自定义表单。<br/>
                流程可以让组织24小时运行，让组织更加柔性，快速部署节省成本。<br/>
                建立以目标为核心的工作执行体系，实时高效的跨部门、子公司协助沟通，让组织的管理制度有效落地执行。
              </div>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={styles['container-img']}>
              <img src={ProductFlow} alt={"产品主页"}/>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 16]} style={{marginBottom: '20px'}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={styles['container-img']}>
              <img src={ProductForm} alt={"产品主页"}/>
            </div>

          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex justify="center" align="center" vertical className={styles['container-msg']}>
              <div>
                <h3>表单引擎</h3>
                基于 Vue 的可视化低代码表单设计器，赋能企业实现低代码开发模式；帮助开发者从传统枯燥的表单代码中解放出来，更多关注业务，快速提高效率，节省研发成本。<br/>
                提供了栅格、标签页、表格等复杂的表单布局，可通过低代码进行动作事件的交互，完成表单复杂的逻辑操作。<br/>
                支持 pc、pad、mobile 多终端布局适配，并可查看展示效果。<br/>
                字段的样式、组件、属性均可实现自定义的扩展，方便快捷的进行个性化配置。
              </div>
            </Flex>
          </Col>
        </Row>
      </div>

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          服务编排
        </h3>
        <Row gutter={[40, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex justify="center" align="center" vertical className={styles['container-msg']}>
              <div>
                <h3>服务编排</h3>
                背景：应用系统的架构演变随着业务的越来越复杂，需要更多的思考、更高维度的抽象。 将组织逻辑与业务实现分离，使业务应用更关注自身的领域内容。<br/>
                目标：将业务流程可视化、最终展现出全局业务视图，并可以动态调整业务链路<br/>
                产出：开发人员只需要注重简单接口（行为）的开发，业务人员可以通过编排系统实现应用及系统的组装和运维<br/>
                优势：
                提高工作效率:流程编排可以自动化执行各种任务和活动，避免了人工干预的繁琐和耗时。
                降低错误率:流程编排可以减少人为错误的发生，通过预设的规则和条件进行自动化的判断和处理，提高了工作的准确性和可靠性。

              </div>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={styles['container-img']}>
              <img src={ServiceLayout} alt={"产品主页"}/>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          统一门户集成平台
        </h3>
        <Row gutter={[40, 16]} style={{marginBottom: '20px'}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex justify="center" align="center" vertical className={styles['container-msg']}>
              <div>
                <h3>统一门户集成平台</h3>
                门户平台是企业为员工、供应商、客户等建设的统一的信息展示和业务处理平台；其核心要点是建设统一的系统入口、信息门户和业务门户；为管理者聚合各种信息、数据、报表等，便于决策，为普通用户整合业务处理入口，方便办公。
              </div>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={styles['container-img']}>
              <img src={PortalHome} alt={"产品主页"}/>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 16]} style={{marginBottom: '20px'}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={styles['container-img']}>
              <img src={PortalTodo} alt={"产品主页"}/>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex justify="center" align="center" vertical className={styles['container-msg']}>
              <div>
                <h3>统一门户集成平台 - 流程中心</h3>
                  集成多个业务系统的流程统一管理，实现不同系统之间的流程信息共享和业务协作，极大的提高员工办公效率。<br/>
                  支持在门户PC端统一发起流程，不需要登录每个子业务系统就行操作。<br/>
                  对自己收到的待办流程有消息提醒支持。<br/>
              </div>
            </Flex>
          </Col>
        </Row>
        <Row gutter={[40, 16]} style={{marginBottom: '20px'}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex justify="center" align="center" vertical className={styles['container-msg']} >
              <div>
                <h3>统一门户集成平台 - 流程详情</h3>
                可以查阅别人发起给自己审批的相关流程，包括表单内容、流程流转图、流程审批等信息。<br/>
                对该流程可以进行审核、驳回、转阅、转办、加签、撤回等相关功能的操作。<br/>
                支持流程3种形式的流程表单打印：<br/>
                &nbsp;&nbsp;1：打印整个流程详情页 <br/>
                &nbsp;&nbsp;2：打印申请单和表单区域 <br/>
                &nbsp;&nbsp;3：打印表单区域<br/>
                支持查看当前流程的整个流程图及每个流程节点的审核信息（审批人员、节点状态、开始时间、结束时间、审批耗时）<br/>
              </div>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={styles['container-img']}>
              <img src={PortalApprove} alt={"产品主页"}/>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          统一权限管理
        </h3>
        <Row gutter={[40, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex justify="center" align="center" vertical className={styles['container-msg']}>
              <div>
                <h3>RBAC</h3>
                <p>
                  基于角色的访问控制（Role-based access control，简称 RBAC），指的是通过用户的角色（Role）赋予其相关权限，这实现了更灵活的访问控制，并提供了一个相比直接授予单个用户权限，更简单、可控的管理方式。
                </p>
                <p>
                  当使用 RBAC 时，通过分析系统用户的实际情况，基于共同的职责和需求，将他们分配给不同的角色。然后可以授予每个用户一个或多个角色，每个角色具有一个或多个权限，这种 用户-角色、角色-权限 间的关系，让我们可以不用再单独管理单个用户，用户从具备的角色里面继承所需的权限，从而使得用户赋权这件事变得更加简单。
                </p>
              </div>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={styles['container-img']}>
              <img src={ProductPrivilege} alt={"RBAC"}/>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          三方平台对接
        </h3>
        <Row gutter={[40, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex justify="center" align="center" vertical className={styles['container-msg']}>
              <div>
                <h3>飞书 or 钉钉</h3>
                <p>
                  支持钉钉、飞书移动端审批代办流程与发起新流程，能够帮助企业领导或员工提高工作效率，即使某天请假或下班后也可以随时随地办公。
                  帮助企业实现高效、便捷、安全、灵活、智能的沟通和协作,为企业带来了极大的便利和价值。
                </p>
                <h3>电子签章法大大</h3>
                <p>
                  1：集成法大大签章SDK<br/>
                  2：促进企业数字化变革的引擎<br/>
                  3：提高业务表单手动签署效率<br/>
                  4：重要流程随时审核<br/>
                </p>
              </div>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className={styles['container-img']}>
              <img src={ProductThird} alt={"飞书"}/>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          核心功能
        </h3>
        <div>
          表单引擎设计器是基于FormMaking的专业表单设计器，可视化低代码开发模式，解放传统枯燥的表单代码，提高效率，节省研发成本。<br/>
          门户平台是企业打造各类信息聚合平台的工具，支持页面的可视化设计、即时预览和在线发布，解决了不同用户有个性化首页需求的问题。<br/>
          FlowablePlus流程引擎插件是基于Bpmn2.0规范开发的在线流程设计器，简洁的界面风格，强大的流程属性配置功能，并且扩展了多个常用的组件，基本能满足90%以上的企业需求。<br/>
          流程引擎SDK，项目中只需引入JAR包，便可快速对接流程相关接口，帮助开发者从传统API接口对接中解放出来，可以最小成本和时间完成流程相关接口集成对接。<br/>
          对接钉钉和飞书APP待办已办列表，集成钉钉和飞书发起流程入口和相关流程核心功能。帮助企业实现高效、便捷、安全、灵活、智能的沟通和协作,为企业带来了极大的便利和价值。<br/>
          Flowable-DMN决策引擎提供了可视化的决策建模工具，用户可以通过拖拽和摆放元素来创建决策模型，无需编写复杂的代码。Flowable-DMN采用了先进的技术和算法，具有出色的性能和稳定性。
        </div>
      </div>
    </div>
  );
};
export default ProductPage;
