import styles from './index.module.less';
import TitleComponents from '../components/title';
import {useContext, useEffect, useState} from 'react';
import {downloadCodeZip, getMyProduct} from "@/api/order";
import {Card, Tag, Space, Empty, Spin, Badge} from "antd";

const MyProduct = () => {
  const [myProducts, setMyProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadMyProduct=()=>{
    setLoading(true);
    getMyProduct().then(res=>{
      const {success, data} = res;
      if(success){
        setMyProducts(data);
      }
      setLoading(false)
    });
  }

  useEffect(() => {
    loadMyProduct();
  }, []);

  const handleDownload = async (orderNo) =>{
    setLoading(true);
    try{
      const {data, headers} = await downloadCodeZip({orderNo})
      let fileName = '';
      if (headers['content-disposition']) {
        fileName = headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
      } else {
        fileName = data.fileName
      }
      // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
      const blob = new Blob([data], { type: 'blob' })
      const dom = document.createElement('a')
      const downUrl = window.URL.createObjectURL(blob)
      dom.href = downUrl
      dom.download = decodeURIComponent(fileName)
      dom.style.display = 'none'
      document.body.appendChild(dom)
      dom.click()
      dom.parentNode.removeChild(dom)
      // window.URL.revokeObjectURL(url)
    }catch(e){
      console.error(e);
    }finally {
      setLoading(false);
      loadMyProduct();
    }
  }

  const genOrderTitle = (item: any)=>{
    return <div style={{fontWeight: "bold"}}>订单号：<Tag>{item.orderNo}</Tag></div>;
  }

  const genDownBtn = (item) => {
    if(item.downCodeStatus === 1) {
      if (item.ifNewVersion === 1) {
        return <Badge size={'small'} offset={[10, -8]} count={`${item.newVersionCode}`}>
          <a style={{cursor: "pointer"}} onClick={() => handleDownload(item.orderNo)}>立即下载</a>
        </Badge>
      } else {
        return <a style={{cursor: "pointer"}}
                  onClick={() => handleDownload(item.orderNo)}>立即下载</a>;
      }
    }
  }

  const queryDocument = (item) => {
    if (item.type === 1) {
      return <a style={{cursor: "pointer"}} href={'https://www.bpmport.com/bpmn/docs/designer/Designer-first.html'}
                target={'_blank'}>在线文档</a>;
    } else if (item.type === 4) {
      return <a style={{cursor: "pointer"}}
                href={'https://www.bpmport.com/bpmn/docs/designer/ActivitiDesigner-first.html'}
                target={'_blank'}>在线文档</a>;
    } else if (item.type === 2) {
      return <a style={{cursor: "pointer"}} href={'https://www.bpmport.com/bpmn/docs/designer/Designer-api.html'}
                target={'_blank'}>在线文档</a>;
    }
  }


  const genMyProductPanel = (products)=>{
    if(!products || products.length===0){
      return <Empty></Empty>
    }
    return products.map((item:any)=>{
      return <Card title={genOrderTitle(item)} className={styles['my-product-item']} >
        <p>
          <div className={styles['product-data-item']}>
            产品名称：&nbsp;&nbsp;
            <Space>{item.productName} - <Tag color={"#2db7f5"}>{item.typeName}</Tag></Space>
          </div>
          <div className={styles['product-data-item']}>
            当前版本号：&nbsp;&nbsp;{item.currentVersionCode}
          </div>
          <div className={styles['product-data-item']}>
            产品价格：&nbsp;&nbsp;￥{item.amount}
          </div>
          <div className={styles['product-data-item']}>
            下单时间：&nbsp;&nbsp;{item.createdTime}
          </div>
          <div className={styles['product-data-item']}>
            授权有效期：&nbsp;&nbsp;{item.orderExpireDate}
          </div>
          <div className={styles['product-data-item']}>
            版本更新时间：&nbsp;&nbsp;{item.versionUpdatedTime}
          </div>
          <div className={styles['product-data-item']}>
            用户更新时间：&nbsp;&nbsp;{item.userUpdatedTime}
          </div>
          <div className={styles['product-data-item']}>
            源码下载地址：&nbsp;&nbsp;
            {genDownBtn(item)}  &nbsp;&nbsp;&nbsp;&nbsp;{queryDocument(item)}
          </div>

        </p>
      </Card>
    });
  }

  return (
    <div className={styles['my-product-data-wrap']}>

      <TitleComponents title="已购产品"/>
      <div className={styles['product-data-content_wrap']}>
        <Spin spinning={loading}>
          {genMyProductPanel(myProducts)}
        </Spin>
      </div>
      {/*<div className={styles['personal-data-content_wrap']}>
        <div className={styles['personal-data-item']}>
          产品名称：&nbsp;&nbsp;{myProduct?.userNo}
        </div>
        <div className={styles['personal-data-item']}>
          订单号：&nbsp;&nbsp;{myProduct?.email}
        </div>
        <div className={styles['personal-data-item']}>
          状态：&nbsp;&nbsp;124
        </div>
        <div className={styles['personal-data-item']}>
          订单金额：&nbsp;&nbsp;124
        </div>
        <div className={styles['personal-data-item']}>
          源码下载：&nbsp;&nbsp;124
        </div>
      </div>*/}
    </div>
  );
};
export default MyProduct;
