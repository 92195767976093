import CommonForm from '@/components/common-form';
import { FormConfig, InputType } from '@/components/common-form/form.types';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, FormInstance, Input, message } from 'antd';
import styles from './index.module.less';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { getLocalStorage, setLocalStorage } from '@/util/util';
import Vrcode, { VerCodeRef } from '@/components/vrcode';
import { LoginApi } from '@/api/login';
import { LocalStorageContext } from '../provider/storage-provider';

const Login = () => {
  const navigator = useNavigate();
  const LocalContext = useContext(LocalStorageContext);
  const formRef = useRef<FormInstance>(null);
  const [inputStatus, setInputStatus] = useState<
    'error' | 'warning' | undefined | ''
  >(undefined);
  const [codeValue, setCodeValue] = useState<string | undefined>(undefined);
  const codeRef = useRef<VerCodeRef>(null);
  const formConfig: FormConfig[] = [
    {
      type: InputType.formitem,
      props: {
        name: 'userNo',
        rules: [{ required: true, message: '请输入用户名' }],
      },
      children: {
        type: InputType.input,
        props: {
          prefix: <UserOutlined style={{ fontSize: 16, color: 'white' }} />,
          className: 'gray-input',
          placeholder: '用户名',
          style: {
            width: 410,
            height: 40,
          },
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        rules: [{ required: true, message: '请输入密码' }],
        name: 'password',
      },
      children: {
        type: InputType.password,
        props: {
          prefix: <LockOutlined style={{ fontSize: 16, color: 'white' }} />,
          className: 'gray-input',
          placeholder: '密码',
          style: {
            width: 410,
            height: 40,
          },
        },
      },
    },
  ];
  useEffect(() => {
    const userAccount = getLocalStorage('userAccount');
    if (userAccount) {
      const userAccountObj = JSON.parse(userAccount);
      formRef.current?.setFieldsValue({
        username: userAccountObj.username,
        password: userAccountObj.password,
        remember: [0],
      });
    }
  }, []);
  const toRegister = () => {
    navigator('/register');
  };
  const toFindPwd = () => {
    navigator('/find-pwd');
  };
  return (
    <div className={styles['login-wrap']}>
      <div className={styles['login-title']}>登录</div>
      <div className={styles['login-content-wrap']}>
        <div className={styles['login-content']}>
          <div className={styles['login-content-box']}>
            <CommonForm
              formConfig={formConfig}
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              ref={formRef}
              initialValues={{
                username: '',
                password: '',
                remember: '',
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles['vrcode-wrap']}>
        <Input
          style={{ width: 206, height: 40, marginRight: 8 }}
          placeholder="验证码"
          status={inputStatus}
          value={codeValue}
          onChange={(value) => {
            setCodeValue(value.target.value);
          }}
          onFocus={() => {
            setInputStatus(undefined);
          }}
        />
        <Vrcode ref={codeRef} />
      </div>
      <Button
        style={{
          width: 164,
          height: 40,
          background: '#14BD85',
          borderRadius: 4,
          color: 'white',
          marginTop: 40,
        }}
        onClick={async () => {
          try {
            const value = await formRef.current?.validateFields();
            value.verifyCode = codeValue;
            if(!codeValue){
              return message.error('验证码不能为空！');
              return;
            }
            /*const code = codeRef.current?.getNum();
            if (codeValue?.toLocaleUpperCase() !== code?.toLocaleUpperCase()) {
              setInputStatus('error');
              return message.error('验证码错误');
            } else {
              setInputStatus(undefined);
            }*/
            const { data } = await LoginApi(value);

            LocalContext?.updateUser(data);
            setLocalStorage('userInfo', data);
            navigator('/');
            message.success('登录成功');
          } catch (error) {
            console.log(error);
          }
        }}
      >
        立即登录
      </Button>
      <div style={{ color: '#14BD85', fontSize: 14, marginTop: 32 , cursor:"pointer"}}
        onClick={toFindPwd}
      >
        忘记密码?
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#666666',
          marginTop: 24,
          cursor: 'pointer',
        }}
        onClick={toRegister}
      >
        没有账号?
        <span style={{ color: '#14BD85', marginLeft: 5 }}>免费注册</span>
      </div>
    </div>
  );
};
export default Login;
